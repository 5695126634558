import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div class="flex justify-center items-center p-5 md:p-10 lg:px-40">
      <div class="container">
        <div>
          <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold pb-10">
            Orrizonte Privacy Policy
          </h1>
          <hr class="border-t border-gray-400"></hr>
        </div>

        <p class="text-sm md:text-base lg:text-lg text-center py-3 md:py-5">
          This Privacy Policy describes how Orrizonte Technologies Private
          Limited ("Orrizonte," "we,""us, " or "our") collects, uses, and
          discloses personal information when you visit our website(the
          "Website") and interact with our services. By accessing or using the
          Website, you consent to the collection, use, and disclosure of your
          personal information as described in this Privacy Policy.
        </p>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            1. Information we collect
          </h3>
          <p className="text-base leading-7 text-justify">
            When you visit the Website, we may collect certain information
            automatically, including your IP address, browser type, operating
            system, and other technical information. We may also use cookies and
            similar tracking technologies to collect information about your
            browsing behavior on the Website. 
            </p>
            <br></br>
            <p className="text-base leading-7 text-justify">
            Additionally, if you choose to
            contact us through the Website or sign up for our services, we may
            collect personal information such as your name, email address, phone
            number, and any other information you choose to provide.
          </p>
        </div>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            2. How we use your information
          </h3>
          <p className="text-base leading-7 text-justify">
          We may use the information we collect for the following purposes:
          </p>
          <br></br>
          <ul className="text-start leading-7 text-base">
            <li>- To provide and maintain the Website and our services</li>
            <li>- To communicate with you about your inquiries or requests</li>
            <li>- To send you marketing communications about our products and services, subject to your
consent where required by law</li>
            <li>- To analyze trends and improve the Website and our services</li>
            <li>- To protect the security and integrity of the Website and our systems</li>
            <li>- To comply with legal obligations</li>
          </ul>
        </div>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            3. Disclosure of your information
          </h3>
          <p className="text-base leading-7 text-justify">
          We may disclose your personal information to third parties in the following circumstances:
          </p>
          <br></br>
          <ul className="text-start leading-7 text-base">
            <li>-With your consent</li>
            <li>-To our service providers who assist us in operating the Website and providing our services</li>
            <li>-To comply with legal obligations or enforce our rights</li>
            <li>-In connection with a merger, acquisition, or sale of assets</li>
          </ul>
        </div>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            4. Data Security
          </h3>
          <p className="text-base leading-7 text-justify">
          We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.
          </p>
        </div>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            5. Your rights
          </h3>
          <p className="text-base leading-7 text-justify">
          You have certain rights with respect to your personal information, including the right to access, correct, or delete your information. You may also have the right to object to or restrict certain types of processing of your information. To exercise your rights, please contact us using the contact information provided below.
          </p>
        </div>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            6. Changes to this Privacy Policy
          </h3>
          <p className="text-base leading-7 text-justify">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will post any updates to this Privacy Policy on the Website, and the updated Privacy Policy will be effective upon posting.
          </p>
        </div>

        <div class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            7. Contact Us
          </h3>
          <p className="text-base leading-7 text-justify">
          If you have any questions or concerns about this Privacy Policy, please contact us at contactus@orrizonte.in.
          </p>
        </div>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
