import React from "react";
import "./OtherServices.scss";

const OtherServices =() =>{
    return(
        <>
         <div className="flex justify-center items-center px-4 lg:px-8 min-h-screen other-services-container">
            <div className="max-w-7xl w-full flex flex-col lg:flex-col items-center lg:items-center p-4 lg:p-16">
                <div className="w-full flex justify-between items-center md:mb-0 flex-col md:flex-row">
                    <h1 className="text-orange-500 text-4xl font-bold">Other Services</h1>
                    <p className="text-gray-600 text-xl leading-loose mt-4 md:mt-0 md:ml-4">
                        In addition to our core services, we also offer
                    </p>
                </div>
                <div className="w-full border border-gray-300 mx-auto mt-3"></div>

                <div className="container mx-auto px-4 py-16">
                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
                    <div className="flex flex-col items-center justify-center space-y-4 rounded-2xl border shadow-md card-container">
                      <img src="/assets/images/otherServices/staffAugmentation.svg" alt="Service Image" className="w-full object-cover rounded-t-2xl" />
                      <h3 className="text-lg font-bold text-gray-800 other-service w-full mt-0 p-3">Staff Augmentation</h3>
                      <p className="text-gray-600 text-center para p-3 text-base">
                        Augment your existing team with skilled professionals on a temporary or permanent basis to meet your project requirements.
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center space-y-4 rounded-2xl border shadow-md card-container">
                      <img src="/assets/images/otherServices/processOutsourcing.svg" alt="Service Image" className="w-full object-cover rounded-t-2xl" />
                      <h3 className="text-lg font-bold text-gray-800 other-service w-full mt-0 p-3">Process Outsourcing</h3>
                      <p className="text-gray-600 text-center para p-3 text-base">
                        Streamline your operations and increase efficiency by outsourcing non-core business processes to our experienced team.
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center space-y-4 rounded-2xl border shadow-md card-container">
                      <img src="/assets/images/otherServices/dataAnalytics.svg" alt="Service Image" className="w-full object-cover rounded-t-2xl" />
                      <h3 className="text-lg font-bold text-gray-800 other-service w-full mt-0 p-3">Data Analytics</h3>
                      <p className="text-gray-600 text-center para p-3 text-base">
                        Unlock the full potential of your data with our advanced analytics services. We help you gain valuable insights to drive informed decision.
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div> 
        </>
    )
}

export default OtherServices;