import React from "react";
import "./WhyChooseOrrizonte.scss";

const WhyChooseOrrizonte = () => {
  return (
    <div className="flex justify-center items-center px-4 lg:px-8 min-h-screen bg-zinc-50 why-choose-container">
      <div className="max-w-7xl w-full flex flex-col lg:flex-col items-center lg:items-center p-4 lg:p-16">
        <div className="w-full flex justify-between items-center md:mb-0 flex-col md:flex-row">
          <h1 className="text-orange-500 text-4xl font-bold">
            Why Choose Orrizonte?
          </h1>
          <p className="text-gray-600 text-xl leading-loose mt-4 md:mt-0 md:ml-4">
            Quality, reliability, innovation, exceptional service.
          </p>
        </div>
        <div className="w-full border border-gray-300 mx-auto mt-3"></div>
        <div className="mt-6 px-6">
          <p>
            Experience-driven solutions, cutting-edge technology, dedicated
            support, streamlined processes, and a proven track record of success
            in IT solutions and services.
          </p>
        </div>

        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 pt-10">
          <div className="shadow-lg bg-white rounded-lg p-6 choose-card-container">
            <div className="flex items-center mb-4">
              <img
                src="/assets/images/home/innovation.svg"
                alt="logo"
                className="w-8 h-12 mr-4"
              />
              <h3 className="text-xl font-semibold">Innovation</h3>
            </div>
            <p className="text-start text-neutral-500">
              We are at the forefront of technological innovation, constantly
              pushing the boundaries of what's possible to drive positive
              change.
            </p>
          </div>
          <div className="shadow-lg bg-white rounded-lg p-6 choose-card-container">
            <div className="flex items-center mb-4">
              <img
                src="/assets/images/home/expertise.svg"
                alt="logo"
                className="w-8 h-12 mr-4"
              />
              <h3 className="text-xl font-semibold">Expertise</h3>
            </div>
            <p className="text-start text-neutral-500">
              With a team of experienced technologists and industry experts, we
              have the skills and knowledge to tackle even the most complex
              challenges.
            </p>
          </div>
          <div className="shadow-lg bg-white rounded-lg p-6 choose-card-container">
            <div className="flex items-center mb-4">
              <img
                src="/assets/images/home/commitment.svg"
                alt="logo"
                className="w-8 h-12 mr-4"
              />
              <h3 className="text-xl font-semibold">
                Commitment to Sustainability
              </h3>
            </div>
            <p className="text-start text-neutral-500">
              Sustainability is at the core of everything we do. From our Smart
              Energy Solutions to our commitment to reducing our own
              environmental footprint.
            </p>
          </div>
          <div className="shadow-lg bg-white rounded-lg p-6 choose-card-container">
            <div className="flex items-center mb-4">
              <img
                src="/assets/images/home/customer-centric.svg"
                alt="logo"
                className="w-8 h-12 mr-4"
              />
              <h3 className="text-xl font-semibold">
                Customer-Centric Approach
              </h3>
            </div>
            <p className="text-start text-neutral-500">
              Your success is our success. We work closely with our clients to
              understand their needs and deliver solutions that exceed their
              expectations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseOrrizonte;
