import React, { useState, useEffect } from "react";
import "./GetInTouch.scss";
import { toast } from 'react-toastify';

const GetInTouch = ({ setVisible}) => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        designation: "",
        description: "",
        purpose:"",
      });

      const [errors, setErrors] = useState({});
      const [touched, setTouched] = useState({});
      const [ipAddress, setIpAddress] = useState("");

      const fetchIpAddress = async () => {
        try {
          const response = await fetch("https://api.ipify.org/?format=json");
          const data = await response.json();
          setIpAddress(data.ip);
        } catch (error) {
          console.error("Error fetching IP address:", error);
        }
      };

      useEffect(() => {
        fetchIpAddress();
      }, []);

      const validateField = (name, value) => {
        let error = "";
        if (name === "fullName" && !value) error = "Full Name is required";
        if (name === "email") {
          if (!value) {
            error = "Email is required";
          } else if (!/\S+@\S+\.\S+/.test(value)) {
            error = "Email is invalid";
          }
        }
        if (name === "phoneNumber") {
          if (!value) {
            error = "Phone Number is required";
          } else if (!/^\d{10}$/.test(value)) {
            error = "Phone Number must be 10 digits";
          }
        }
        if (name === "designation" && !value) error = "Designation is required";
        if (name === "companyName" && !value) error = "Company Name is required";
        if (name === "description" && !value) error = "Description is required";
        if (name === "purpose" && !value) error = "Purpose is required";
    
        return error;
      };

      const validate = () => {
        const newErrors = {};
        Object.keys(formData).forEach((name) => {
          const error = validateField(name, formData[name]);
          if (error) newErrors[name] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phoneNumber" && value.length > 10) {
          return;
        }
    
        setFormData({
          ...formData,
          [name]: value,
        });
        const error = validateField(name, value);
        setErrors({
          ...errors,
          [name]: error,
        });
      };

      const handleBlur = (e) => {
        const { name, value } = e.target;
        setTouched({
          ...touched,
          [name]: true,
        });
        const error = validateField(name, value);
        setErrors({
          ...errors,
          [name]: error,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
    
        try {
          const response = await fetch('https://apicrm.orrizonte.in/api/v1/common/crm/orrizonte/enquiry', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              mobileNumber: formData.phoneNumber,
              email: formData.email,
              name: formData.fullName,
              designation: formData.designation,
              company: formData.companyName,
              purpose: formData.purpose,
              description: formData.description,
              client_ip: ipAddress,
            }),
          });
    
          if (response.ok) {
            toast.success('Enquiry submitted successfully');
            setFormData({
              fullName: "",
              email: "",
              phoneNumber: "",
              companyName: "",
              designation: "",
              description: "",
              purpose: "",
            });
            console.log('Form submitted successfully');
          } else {
            toast.error("Error occurred");
            console.error('Form submission failed');
          }
        } catch (error) {
          toast.error("Error occurred");
          console.error('An error occurred:', error);
        }
        finally{setVisible(false)}
      };

      return (
        <>
        <div className="rounded-lg flex flex-col lg:flex-row">
        <div className="lg:w-3/5 p-4">
          <h2 className="text-start font-bold text-4xl question">Still have questions?</h2>
          <img
            src="/assets/images/getInTouch/getInTouch.svg"
            alt="logo"
            className="my-4"
          />
          <p className="text-neutral-500 text-sm text-start mt-10">
            Please feel free to reach out to us using the form on the side. Our
            dedicated team is ready to provide you with the information and
            support you need.
          </p>
        </div>
    
        <div className="lg:w-2/5 p-4 form-container">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold details-fill">Fill your basic details</h3>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4 ">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="w-full md:w-1/2">
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Full Name"
                  className="mt-1 block w-full rounded-full border-gray-300 shadow-sm p-2"
                />
                {(touched.fullName || errors.fullName) && <p className="error-text text-red-500 mt-1">{errors.fullName}</p>}
              </div>
              <div className="w-full md:w-1/2 mt-4 md:mt-0">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                  className="mt-1 block w-full rounded-full border-gray-300 shadow-sm p-2"
                />
                {(touched.email || errors.email) && <p className="error-text text-red-500 mt-1">{errors.email}</p>}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="w-full md:w-1/2">
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Phone Number"
                  className="mt-1 block w-full rounded-full border-gray-300 shadow-sm p-2"
                />
                {(touched.phoneNumber || errors.phoneNumber) && <p className="error-text text-red-500 mt-1">{errors.phoneNumber}</p>}
              </div>
              <div className="w-full md:w-1/2 mt-4 md:mt-0">
                <input
                  type="text"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Designation"
                  className="mt-1 block w-full rounded-full border-gray-300 shadow-sm p-2"
                />
                {(touched.designation || errors.designation) && <p className="error-text text-red-500 mt-1">{errors.designation}</p>}
              </div>
            </div>
            <div>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Company Name"
                className="mt-1 block w-full rounded-full border-gray-300 shadow-sm p-2"
              />
              {(touched.companyName || errors.companyName) && <p className="error-text text-red-500 mt-1">{errors.companyName}</p>}
            </div>
            <div>
              <select
                name="purpose"
                value={formData.purpose}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 block w-full rounded-full border-gray-300 shadow-sm p-2"
              >
                <option value="">Purpose</option>
                <option value="Cklassrooms">Cklassrooms</option>
                <option value="LetsConnect">LetsConnect</option>
                <option value="Other">Other</option>
              </select>
              {(touched.purpose || errors.purpose) && <p className="error-text text-red-500 mt-1">{errors.purpose}</p>}
            </div>
            <div>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Description"
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm p-2 h-28"
              ></textarea>
              {(touched.description || errors.description) && <p className="error-text text-red-500 mt-1">{errors.description}</p>}
            </div>
            <button 
              type="submit" 
              className="w-full bg-orange-500 text-white py-2 rounded-full shadow-sm hover:bg-orange-600"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      </>
      );
}

export default GetInTouch;