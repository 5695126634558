import React from "react";
import "./Header.scss";

const Header = ({setVisible, visible}) => {
  return (
    <header className="header-bg-color py-5 flex justify-between px-5">
      <a href="/home">
      <img src="/assets/images/orrizonte_logo.svg" alt="logo" />
      </a>
      <button type="button" onClick={() => setVisible(!visible)} className="border-orange text-orange font-medium py-0.5 px-4 rounded">
        Get in touch
      </button>
    </header>
  );
};

export default Header;
