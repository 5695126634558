export const logos = [
    { id: 1, src: '../assets/images/trustedBy/google-logo.svg', alt: 'Google' },
    { id: 2, src: '../assets/images/trustedBy/meta-logo.svg', alt: 'Meta' },
    { id: 3, src: '../assets/images/trustedBy/amazon-logo.svg', alt: 'Amazon' },
    { id: 4, src: '../assets/images/trustedBy/flipkart-logo.svg', alt: 'Flipkart' },
    { id: 5, src: '../assets/images/trustedBy/unicef-logo.svg', alt: 'Unicef' },
    { id: 6, src: '../assets/images/trustedBy/nsdc-logo.svg', alt: 'NSDC' },
    { id: 7, src: '../assets/images/trustedBy/quess-logo.svg', alt: 'Quess' },
    { id: 8, src: '../assets/images/trustedBy/vaco-logo.svg', alt: 'Vaco' },
    { id: 9, src: '../assets/images/trustedBy/sortyfy-logo.svg', alt: 'Sortyfy' },
    { id: 10, src: '../assets/images/trustedBy/taskmo-logo.svg', alt: 'Taskmo' },
    { id: 11, src: '../assets/images/trustedBy/yuwaah-logo.svg', alt: 'Yuwaah' },
    { id: 12, src: '../assets/images/trustedBy/niit-logo.svg', alt: 'Niit' },
    { id: 13, src: '../assets/images/trustedBy/best-logo.svg', alt: 'Best' },
    { id: 14, src: '../assets/images/trustedBy/tide-logo.svg', alt: 'Tide' },
];