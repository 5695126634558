import React from "react";
import "./TrustedBy.scss";
import { useState, useEffect, useRef } from "react";
import { logos } from '../../../../../json/logoConfig';

const TrustedBy = ({ setVisible, visible }) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        const scroll = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollLeft += 1;
                if (
                    scrollRef.current.scrollLeft >=
                    scrollRef.current.scrollWidth / 2
                ) {
                    scrollRef.current.scrollLeft = 0;
                }
            }
        };

        const interval = setInterval(scroll, 10);

        return () => clearInterval(interval);
    }, []);


    return (
        <div className="py-8 lg:py-4">
            <div>
                <h2 className="text-gray-600 text-xl font-bold">TRUSTED BY</h2>
            </div>
            <div
                ref={scrollRef}
                className="flex items-center overflow-hidden whitespace-nowrap w-full py-4 lg:py-8"
            >
                {logos.concat(logos).map((logo) => (
                    <div
                        key={logo.id}
                        className="flex-shrink-0 w-1/7 mx-8"
                    >
                        <img
                            src={logo.src}
                            alt={logo.alt}
                            // className="h-12 md:h-16 lg:h-16"
                            className={
                                logo.id === 11 || logo.id === 12 || logo.id === 13
                                    ? 'h-20 md:h-28 lg:h-24'
                                    : 'h-12 md:h-16 lg:h-16'
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TrustedBy;