import React from "react";
import "./CommonDialog.scss";
import { Dialog } from 'primereact/dialog';

const CommonDialog = ({visible, setVisible, children}) => {
    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, {setVisible: setVisible});
      });
    return (
        <div className="card flex justify-content-center">
            <Dialog visible={visible} modal style={{ width: '50rem' }} onHide={() => setVisible(false)}>
                {childrenWithProps}
                <img
              src="/assets/images/getInTouch/cancel.svg"
              alt="logo"
              className="w-6 h-6 absolute top-3 right-4"
              onClick={() => setVisible(false)} 
            />
            </Dialog>
        </div>
    )
}

export default CommonDialog;