import React from "react";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
  const terms = [
    {
      title: "Acceptance of Terms",
      content:
        "By accessing or using the Website, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree to these Terms, you may not access or use the Website.",
    },
    {
      title: "Use of Website",
      content:
        "You may use the Website for lawful purposes only and in accordance with these Terms. You are prohibited from using the Website in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party's use of the Website.",
    },
    {
      title: "Intellectual Property",
      content:
        "All content on the Website, including text, graphics, logos, images, and software, is the property of Orrizonte Technologies Private Limited or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, or create derivative works of any content on the Website without the express written consent of Orrizonte Technologies Private Limited.",
    },
    {
      title: "Privacy Policy",
      content:
        "Your use of the Website is subject to our Privacy Policy, which is incorporated into theseTerms by reference. By using the Website, you consent to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy.",
    },
    {
      title: "Limitation of Liability",
      content:
        "To the fullest extent permitted by law, Orrizonte Technologies Private Limited shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in any way connected with your use of the Website or any services offered through the Website, whether based on warranty, contract, tort, or any other legal theory.",
    },
    {
      title: "Governing Law",
      content:
        "These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in New Delhi, India.",
    },
    {
      title: "Changes to Terms and Conditions",
      content:
        "Orrizonte Technologies Private Limited reserves the right to modify or revise these Terms at any time without notice. By continuing to use the Website after any changes to these Terms, you agree to be bound by the revised Terms.",
    },
    {
      title: "Contact Us",
      content:
        "If you have any questions or concerns about these Terms and Conditions, please contact us at contactus@orrizonte.in.",
    },
  ];

  return (
    <div class="flex justify-center items-center p-5 md:p-10 lg:px-40">
    <div class="container">
      <div>
        <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold ">
          Orrizonte Terms & Conditions
        </h1>
        <h6 class="text-lg md:text-xl lg:text-2xl font-medium p-3 md:p-5 text-neutral-700">
          Legal Information & Notices
        </h6>
      </div>

      <hr class="border-t border-gray-400"></hr>

      <p class="text-sm md:text-base lg:text-lg text-center py-3 md:py-5">
        These Terms and Conditions ("Terms") govern your use of the Orrizonte
        Technologies PrivateLimited website (the "Website") and any services
        offered through the Website. By accessing or using the Website, you
        agree to be bound by these Terms. If you do not agree to theseTerms,
        please do not access or use the Website.
      </p>

      {terms.map((term, index) => (
        <div key={index} class="my-3 md:my-5">
          <h3 className="text-base md:text-lg lg:text-xl font-medium text-start pb-2">
            {index + 1}. {term.title}
          </h3>
          <p className="text-base leading-7 text-justify">
            {term.content}
          </p>
        </div>
      ))}
    </div>
    </div>
  );
};

export default TermsAndConditions;
