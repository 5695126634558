import './App.scss';
import { Navigate, Route, Routes, useLocation  } from "react-router-dom";
import Home from './app/screens/Home/Home';
import TermsAndConditions from './app/screens/Terms-And-Conditions/TermsAndConditions';
import Header from './app/modules/shared/components/Header/Header';
import Footer from './app/modules/shared/components/Footer/Footer';
import "./index.scss"
import PrivacyPolicy from './app/screens/Privacy-Policy/PrivacyPolicy';
import { useState } from 'react';
import CommonDialog from './app/modules/shared/CommonDialog/CommonDialog';
import GetInTouch from './app/modules/shared/dialogs/GetInTouch/GetInTouch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/home';

  return (
    <div className="App">

      {!isHomePage && <Header setVisible={setVisible} visible={visible} />}
      <Routes>
        <Route
            path="/home"
            element={
              <Home setVisible={setVisible} visible={visible}/>
            }
          />
        <Route
            path="/terms-and-conditions"
            element={
              <TermsAndConditions/>
            }
          />
        <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy/>
            }
          />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        <Footer />
        <CommonDialog visible={visible} setVisible={setVisible}>
          <GetInTouch />
        </CommonDialog>
        <ToastContainer />
    </div>
  );
}

export default App;
